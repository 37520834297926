@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  background-color: #F1EFE7 !important;
  margin: 0;
  font-family: 'Poppins', sans-serif;

  // button css
  .btn-orange {
    background-color: #E57A4D;
    border-color: #E57A4D;
    color: #ffffff;
  }

  .btn-orange:hover {
    background-color: #E57A4D !important;
    border-color: #E57A4D !important;
    color: #ffffff !important;
  }

  .btn-grey {
    background-color: #BDBDBD;
    border-color: #BDBDBD;
    color: #000000;
  }

  .btn-grey:hover {
    background-color: #BDBDBD !important;
    border-color: #BDBDBD !important;
    color: #000000 !important;
  }

  .btn-light-blue {
    background-color: #ADD6DA;
    border-color: #ADD6DA;
    color: #000000;
  }

  .btn-light-blue:hover {
    background-color: #ADD6DA !important;
    border-color: #ADD6DA !important;
    color: #000000 !important;
  }

  .btn-search-btn-blue {
    background-color: #ADD6DA;
  }

  .btn-search-btn-blue:hover {
    background-color: #ADD6DA;
  }

  .btn-light:hover {
    background-color: #ffffff !important;
  }

  .slick-slide img {
    display: inline !important;
  }


  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #000000 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .form-check-input:checked {
    background-color: #198754;
    border-color: #198754;
  }


  // text css
  .text-grey {
    color: #929292;
  }

  .pointer {
    cursor: pointer;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}


// pagination css

.flex-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.flex-container>div {
  padding-top: 10px;
}

.paginate-ctn {
  display: flex;
}

.round-effect {
  color: #DD4124;
  cursor: pointer;
  /*   border:1px solid red; */

  font-size: 16px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-decoration: none;
}

.round-effect:hover {
  text-decoration: none;
  background: #DD4124;
  color: #000000;
}

.round-effect:hover a {
  text-decoration: none;
  color: #000000;
}

/* .active {
  background: #DD4124;
} */

a:link {
  text-decoration: none;
  color: #DD4124;
}

.pagination .page-item {
  margin: 5px 10px;
  color: #000000;
}

.page-link {
  border-radius: 10px;
  background: #F9F9F9;
  color: #000000 !important;
  padding: 8px 15px;
}

.page-item.active .page-link {
  background: #E57A4D;
  border: none;
  padding: 8px 15px;
}


.page-item .page-link {
  color: #000000;
}

.prevBtn {
  border-radius: 50% !important;
  // padding: 8px 15px;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 50%;
}

.nextBtn {
  border-radius: 50% !important;
  // padding: 8px 15px;
  overflow: hidden;
  border: 1px solid #000000;
}

.disbaled_Btn {
  border-radius: 50% !important;
  // padding: 8px 15px;
  overflow: hidden;
  border: 1px solid #8f8f8f;
}


.fs-1,
.fs-2,
.fs-3,
.fs-4,
.fs-5,
.fs-6 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.pointer {
  cursor: pointer;
}

.long_text_wrap {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.star-container {
  padding-left: 0px !important;
  padding-right: 0px !important;

  .widget-svg {
    width: 20px !important;
    height: 20px !important;
  }
}