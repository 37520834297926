.card-1 {
    border: 2px solid #47CD89;
    background-color: #ABEFC6;

    img {
        width: 70px;
        height: 70px;
    }
}

.card-2 {
    border: 2px solid #FD853A;
    background-color: #FECDCA;

    img {
        width: 70px;
        height: 70px;
    }
}

.card-3 {
    border: 2px solid #FD6F8E;
    background-color: #FECCD6;

    img {
        width: 70px;
        height: 70px;
    }
}

.card-4 {
    border: 2px solid #BDB4FE;
    background-color: #E9D7FE;

    img {
        width: 70px;
        height: 70px;
    }
}

.card-5 {
    border: 2px solid #53B1FD;
    background-color: #B2DDFF;

    img {
        width: 70px;
        height: 70px;
    }
}

.card-6 {
    border: 2px solid #F670C7;
    background-color: #FCCEEE;

    img {
        width: 70px;
        height: 70px;
    }
}

.Title {
    font-size: 24px !important;
}



.table {
    background-color: transparent !important;
    // --bs-table-color-type: initial;
    // --bs-table-bg-type: initial;
    // --bs-table-color-state: initial;
    // --bs-table-bg-state: initial;
    // --bs-table-color: var(--bs-emphasis-color);
    --bs-table-bg: transparent !important;

    th {
        font-weight: 400;
    }

    td {
        .text_wrap {
            width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .table_data_image {
        width: 30px;
        height: 30px
    }

    // --bs-table-border-color: var(--bs-border-color);
    // --bs-table-accent-bg: transparent;
    // --bs-table-striped-color: var(--bs-emphasis-color);
    // --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
    // --bs-table-active-color: var(--bs-emphasis-color);
    // --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
    // --bs-table-hover-color: var(--bs-emphasis-color);
    // --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
    // width: 100%;
    // margin-bottom: 1rem;
    // vertical-align: top;
    // border-color: var(--bs-table-border-color);
}
.Title {
    font-size: 26px !important;
}


@media only screen and (min-width:0px) and (max-width:992px) {
    .card-1 {
        border: 2px solid #47CD89;
        background-color: #ABEFC6;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .card-2 {
        border: 2px solid #FD853A;
        background-color: #FECDCA;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .card-3 {
        border: 2px solid #FD6F8E;
        background-color: #FECCD6;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .card-4 {
        border: 2px solid #BDB4FE;
        background-color: #E9D7FE;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .card-5 {
        border: 2px solid #53B1FD;
        background-color: #B2DDFF;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .card-6 {
        border: 2px solid #F670C7;
        background-color: #FCCEEE;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .table {
        background-color: transparent !important;
        // --bs-table-color-type: initial;
        // --bs-table-bg-type: initial;
        // --bs-table-color-state: initial;
        // --bs-table-bg-state: initial;
        // --bs-table-color: var(--bs-emphasis-color);
        --bs-table-bg: transparent !important;

        th {
            font-weight: 400;
        }

        td {
            .text_wrap {
                width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .table_data_image {
            width: 50px;
            height: 50px
        }

        .input-group {
            .form-select {
                width: 130px;
                font-size: 14px
            }
        }

        // --bs-table-border-color: var(--bs-border-color);
        // --bs-table-accent-bg: transparent;
        // --bs-table-striped-color: var(--bs-emphasis-color);
        // --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
        // --bs-table-active-color: var(--bs-emphasis-color);
        // --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
        // --bs-table-hover-color: var(--bs-emphasis-color);
        // --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
        // width: 100%;
        // margin-bottom: 1rem;
        // vertical-align: top;
        // border-color: var(--bs-table-border-color);
    }
    .Title {
        font-size: 22px;
    }

}

@media only screen and (min-width:1024px) {
    .Title {
        font-size: 22px ;
    }
}