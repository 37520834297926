  .mentorsCardMentors {
    .card {



      .card-body {
        position: absolute;
        left: 5px;
        top: 20px;

        .card-text {
          .text-wrap {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            width: 100%;
            /* Use 100% width to allow flexibility */
            line-height: 2;
          }
        }
      }

      .cardBg0 {
        background-color: #E4E7EC !important;
        position: relative;
        left: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 60px;
        height: 170px;
      }

      .cardBg1 {
        background-color: #E9D7FE !important;
        position: relative;
        left: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 60px;
        height: 170px;
      }

      .cardBg2 {
        background-color: #FECDCA !important;
        position: relative;
        left: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 60px;
        height: 170px;
      }

      .cardBg3 {
        background-color: #FEDF89 !important;
        position: relative;
        left: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 60px;
        height: 170px;
      }

      .cardBg4 {
        background-color: #ABEFC6 !important;
        position: relative;
        left: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 60px;
        height: 170px;
      }

      .cardBg5 {
        background-color: #E4E7EC !important;
        position: relative;
        left: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 60px;
        height: 170px;
      }
    }
  }


  @media only screen and (min-width:0px) and (max-width:768px) {
    .mentorsCardMentors {
      .card {
        .card-body {
          position: absolute;
          left: 5px;
          top: 20px;
  
          .card-text {
            .text-wrap {
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              width: 100%;
              /* Use 100% width to allow flexibility */
              line-height: 2;
            }
          }
        }
  
        .cardBg0 {
          background-color: #E4E7EC !important;
          position: relative;
          left: 0px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: 60px;
          height: 170px;
        }
  
        .cardBg1 {
          background-color: #E9D7FE !important;
          position: relative;
          left: 0px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: 60px;
          height: 170px;
        }
  
        .cardBg2 {
          background-color: #FECDCA !important;
          position: relative;
          left: 0px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: 60px;
          height: 170px;
        }
  
        .cardBg3 {
          background-color: #FEDF89 !important;
          position: relative;
          left: 0px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: 60px;
          height: 170px;
        }
  
        .cardBg4 {
          background-color: #ABEFC6 !important;
          position: relative;
          left: 0px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: 60px;
          height: 170px;
        }
  
        .cardBg5 {
          background-color: #E4E7EC !important;
          position: relative;
          left: 0px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: 60px;
          height: 170px;
        }
      }
    }
  }