
  
// pagination css

.flex-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .flex-container>div {
    padding-top: 10px;
  }
  
  .paginate-ctn {
    display: flex;
  }
  
  .round-effect {
    color: #DD4124;
    cursor: pointer;
    /*   border:1px solid red; */
  
    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-decoration: none;
  }
  
  .round-effect:hover {
    text-decoration: none;
    background: #DD4124;
    color: #000000;
  }
  
  .round-effect:hover a {
    text-decoration: none;
    color: #000000;
  }
  
  /* .active {
    background: #DD4124;
  } */
  
  a:link {
    text-decoration: none;
    color: #DD4124;
  }
  
  .pagination .page-item {
    margin: 5px 10px;
    color: #000000;
  }
  
  .page-link {
    border-radius: 10px;
    background: #F9F9F9;
    color: #000000 !important;
    padding: 8px 15px;
  }
  
  .page-item.active .page-link {
    background: #E57A4D;
    border: none;
    padding: 8px 15px;
  }
  
  
  .page-item .page-link {
    color: #000000;
  }
  
  .prevBtn {
    border-radius: 50% !important;
    // padding: 8px 15px;
    overflow: hidden;
    border: 1px solid #000000;
    border-radius: 50%;
  }
  
  .nextBtn {
    border-radius: 50% !important;
    // padding: 8px 15px;
    overflow: hidden;
    border: 1px solid #000000;
  }
  
  .disbaled_Btn {
    border-radius: 50% !important;
    // padding: 8px 15px;
    overflow: hidden;
    border: 1px solid #8f8f8f;
  }
  