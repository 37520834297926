.userIcon {
    width: 60px;
    height: 60px;
}



@media only screen and (min-width:0px) and (max-width:768px) {
    .userIcon {
        width: 50px;
        height: 50px;
    }

}