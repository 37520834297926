.report-container {
    .dt-buttons {
        .dt-button {
            border: 1px solid black;
            padding: 5px 20px;
            border-radius: 5px;
            // margin-left: 5px;
            background-color: transparent;
        }
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        color: inherit !important;
        background-color: #E57A4D !important;
        font-weight: 600 !important;
        border-radius: 5px !important;
    }

    .dataTables_wrapper .dataTables_filter {
        float: right !important;
    }


    .dataTables_filter {
        margin: 20px 0px !important;
    }

    .paging_full_numbers {
        margin-top: 10px !important;
    }

    .dataTables_length {
        margin-top: 10px !important;
    }
    .dataTables_length{
        margin-top: 10px;
    }
}

@media only screen and (min-width:0px) and (max-width:768px) {
    .report-container {
        .dt-buttons {
            display: flex;
            justify-content: space-between;

            .dt-button {
                width: 100%;
                border: 1px solid black;
                padding: 5px 20px;
                border-radius: 5px;
                margin-left: 5px;
                background-color: transparent;
            }
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button.current,
        .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: inherit !important;
            background-color: #E57A4D !important;
            font-weight: 600 !important;
            border-radius: 5px !important;
        }

        // .dataTables_wrapper .dataTables_filter {
        //     // float: left !important;
        // }


        .dataTables_filter {
            margin: 30px 0px !important;

            input {
                width: 130px;
            }
        }
    }

}