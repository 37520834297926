.side-bar {
    background-color: #F8F7F2;
    height: 100vh;
    width: 100%;
    overflow: auto;
}

.page-container {
    height: 100vh;
    width: 100%;
    overflow: auto;
    font-family: 'Poppins', sans-serif;
}

@media only screen and (min-width:0px)and (max-width:992px)  {
    .page-container {
        height: calc(100vh - 90px);
        width: 100%;
        overflow: auto;
        font-family: 'Poppins', sans-serif;
    }
}