.login-main-container {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #ADD6DA;
    font-family: 'Poppins', sans-serif;

    .sub-container {
        background-color: #ffffff66;
    }

    .forgotPasswordText {
        color: #297ADA;
    }

    .forgotPasswordText:hover {
        border-bottom: 1px solid #297ADA;
    }
}