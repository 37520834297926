.accordion-button {
  background-color: transparent !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}
.quetionbtn {
  background-color: #e6e6e6;
}
.active-circle {
  background-color: #0d98ba;
  color: #ffffff;
}
.active-form-step1 {
  background-color: #add6da;
}
.active-form-step2 {
  background-color: #abefc6;
}
.active-form-step3 {
  background-color: #fddcab;
}
.active-form-step4 {
  background-color: #d9d6fe;
}
.active-form-step5 {
  background-color: #fecdca;
}
.star-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.progress{
    height: 0.5rem !important;
}

