.otp-main-container {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: #ADD6DA;
    font-family: 'Poppins', sans-serif;

    .sub-container {
        background-color: #ffffff66;
    }
}