.testimonialcard0 {
    background-color: #ADD6DA;
    
    height: max-content;
}

.testimonialcard1 {
    background-color: #FDDCAB;
    
    height: max-content;
}

.testimonialcard2 {
    background-color: #ABEFC6;
    
    height: max-content;
}

.testimonialcard {
    position: relative;
    height: max-content;
    background-color: #FDDCAB;
}

.cardHoverChild {
    display: none;
    background-color: #00000069;
    position: absolute;
    top: auto;
    left: 0;
    width: 100%;
    height: max-content;
    z-index: 1;
}

.testimonialcard:hover .cardHoverChild {
    display: block;
}

@media only screen and (min-width:0px) and (max-width:768px) {
    .testimonialcard0 {
        background-color: #ADD6DA;
        
        height: 290px;
    }
    
    .testimonialcard1 {
        background-color: #FDDCAB;
        
        height: 290px;
    }
    
    .testimonialcard2 {
        background-color: #ABEFC6;
        
        height: 290px;
    }
    
    .testimonialcard {
        position: relative;
        height: 290px;
        background-color: #FDDCAB;
    }
    
    .cardHoverChild {
        display: none;
        background-color: #00000069;
        position: absolute;
        top: auto;
        left: 0;
        width: 100%;
        height: 290px;
        z-index: 1;
    }
    
    .testimonialcard:hover .cardHoverChild {
        display: block;
    }
}
@media only screen and (min-width:769px) and (max-width:992px) {
    .testimonialcard0 {
        background-color: #ADD6DA;
        
        height: 270px;
    }
    
    .testimonialcard1 {
        background-color: #FDDCAB;
        
        height: 270px;
    }
    
    .testimonialcard2 {
        background-color: #ABEFC6;
        
        height: 270px;
    }
    
    .testimonialcard {
        position: relative;
        height: 270px;
        background-color: #FDDCAB;
    }
    
    .cardHoverChild {
        display: none;
        background-color: #00000069;
        position: absolute;
        top: auto;
        left: 0;
        width: 100%;
        height: 270px;
        z-index: 1;
    }
    
    .testimonialcard:hover .cardHoverChild {
        display: block;
    }
}